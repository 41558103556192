/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023 中徽为民技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年10月17日</li>
 * <li>@author     : wzq</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import permission from "@js/directives/permission";

export default {
  install(vue) {
    vue.directive("p", permission);
  }
}
